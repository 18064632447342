<template>
	<div class="h-full overflow-y-hidden">
		<div
			id="errorMessage"
			:class="{'!top-0': errorMessage.error}"
			class="transition-all duration-500 h-[35px] top-[-35px] fixed w-screen z-[1000] flex justify-center"
		>
			<div
				class="fixed h-[35px] w-screen bg-amber-400 flex items-center justify-center text-amber-800 font-bold text-sm"
			>
				{{ errorMessage.message }}
			</div>
		</div>
		<div
			id="successMessage"
			:class="{'!top-0': successMessage.success}"
			class="transition-all duration-500 h-[35px] top-[-35px] fixed w-screen z-[1000] flex justify-center"
		>
			<div
				class="fixed h-[35px] w-screen bg-green-300 flex items-center justify-center text-green-700 font-bold text-sm"
			>
				{{ successMessage.message }}
			</div>
		</div>
		<Loader v-if="isLoading" />
		<div
			v-if="!locationData"
			class="flex flex-col h-screen w-screen items-start sm:items-center justify-center px-3 text-slate-400"
		>
			<div class="font-extrabold text-7xl md:text-9xl text-slate-300 pb-7">Oops!</div>
			<div class="text-sm md:text-base">It seems as though something's gone wrong.</div>
			<div class="text-sm md:text-base">Please contact us via the chat or try again later</div>
		</div>
		<div v-else :class="{'z-20 relative': mobileMainNavOpen}" class="flex flex-row h-full w-full md:z-10">
			<div
				@click="mobileMainNavOpen = !mobileMainNavOpen"
				:class="{hidden: !mobileMainNavOpen}"
				class="fixed md:hidden w-screen h-screen bg-slate-100/70 dark:bg-slate-900/50 z-40 backdrop-blur-sm"
			></div>
			<div
				:class="{'translate-x-0': mobileMainNavOpen}"
				class="-translate-x-full transition md:-translate-x-0 fixed w-[280px] md:w-[250px] xl:w-[300px] shrink-0 z-50 md:z-0"
			>
				<div class="w-full h-screen">
					<div
						:class="{'translate-x-0': mobileMainNavOpen}"
						class="-translate-x-full transition md:-translate-x-0 fixed h-full overflow-y-scroll pb-5 bg-sky-950 dark:bg-slate-800 pl-2 z-50 md:z-0 shadow-lg md:shadow-none w-[280px] md:w-[250px] xl:w-[300px] duration-300"
					>
						<div class="h-28 md:h-28 lg:h-32"></div>
						<button
							@click="mobileMainNavOpen = !mobileMainNavOpen"
							class="fixed top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-sky-700 rounded-sm md:hidden hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
						>
							<span class="sr-only">Close main menu</span>
							<font-awesome-icon :icon="['far', 'times']" class="h-6 w-6" />
						</button>
						<div v-for="(item, index) in filteredNavItems" :key="index" class="text-white w-full text-sm md:text-base">
							<router-link
								:to="{name: item.route.name, hash: item.route.hash}"
								exact-active-class="bg-slate-100 hover:!bg-slate-50 text-sky-900 shadow md:shadow-none dark:bg-slate-900 dark:hover:!bg-slate-700 dark:text-white border-l-4 border-l-tdc-blue-500 !no-underline"
								:active-class="
									item.hasSub
										? 'bg-slate-100 hover:!bg-slate-50 text-sky-900 shadow md:shadow-none dark:bg-slate-900 dark:text-white dark:hover:!bg-slate-700 hover:dark:text-white border-l-4 border-l-tdc-blue-500 !no-underline'
										: ''
								"
								class="block w-full pl-2 py-4 dark:text-slate-400 hover:bg-sky-900 hover:underline dark:hover:bg-slate-700 dark:hover:text-white border-l-4 border-transparent"
							>
								<div class="flex flex-row items-center">
									<font-awesome-icon
										:icon="['far', item.icon]"
										class="capitalize text-slate-400 h-4 mr-2 -mt-0.5"
										fixed-width
									/>
									<div class="relative">
										<span
											v-if="item.showNewLabel"
											class="flex items-center absolute text-[9px] -mt-[14px] text-amber-500"
											>{{ $t("mol.mainNav.label.New").toUpperCase()
											}}<font-awesome-icon :icon="['far', 'lightbulb-on']" class="ml-[2px] -mt-[2px]"
										/></span>
										{{ $t(item.label) }}
									</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="grow-0 w-full md:ml-[250px] xl:ml-[300px]">
				<div class="block h-[100px] sm:h-[110px] lg:h-[135px]"></div>
				<div class="px-3 sm:px-4 md:px-5 xl:px-10 max-w-[1400px]">
					<div class="fixed w-screen z-10 md:z-30 left-0 top-0">
						<div
							class="flex items-center justify-center bg-slate-100 px-1 py-2 dark:text-slate-300 dark:bg-slate-700 text-xs"
						>
							<span class="flex items-center">
								<font-awesome-icon :icon="['fas', 'check-circle']" class="h-4 mr-1 text-tdc-green-500" />
								{{ $t("mol.header.label.No cure")
								}}<span class="text-tdc-blue-500 font-bold tracking-tight"
									>&nbsp;{{ $t("mol.header.label.no pay") }}</span
								>
								{{ error }}
							</span>
							<span class="hidden md:flex items-center md:mx-7">
								<font-awesome-icon :icon="['fas', 'check-circle']" class="h-4 mr-1 text-tdc-green-500" />
								<span class="text-tdc-blue-500 font-bold tracking-tight"
									>{{ $t("mol.header.label.Free cancellation") }}&nbsp;</span
								>{{ $t("mol.header.label.14 days before party date") }}
							</span>
							<span class="hidden md:flex items-center">
								<font-awesome-icon :icon="['fas', 'check-circle']" class="h-4 mr-1 text-tdc-green-500" />
								<span class="text-tdc-blue-500 font-bold tracking-tight"
									>{{ $t("mol.header.label.100% match") }}&nbsp;</span
								>
								{{ $t("mol.header.label.with your DJ") }}
							</span>
						</div>
						<div class="bg-white px-1 lg:px-6 py-2.5 lg:py-1 dark:bg-slate-800 md:shadow-lg">
							<div class="flex items-center mx-auto max-w-screen-xl">
								<div class="flex items-center flex-row">
									<button
										@click="mobileMainNavOpen = !mobileMainNavOpen"
										class="inline-flex items-center p-2 ml-1 text-sm text-sky-900 bg-slate-100 rounded-sm md:hidden hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-slate-200 dark:text-slate-100 dark:bg-slate-700 dark:hover:bg-slate-700 dark:focus:ring-slate-600"
									>
										<span class="sr-only">Open main menu</span>
										<font-awesome-icon :icon="['far', 'bars']" class="h-6 w-6" />
									</button>
									<div
										v-if="allianceStatus"
										class="relative w-[150px] min-[360px]:w-[200px] min-[460px]:w-[250px] min-[520px]:w-[300px]"
										@mouseleave="allianceMenuOpen = false"
									>
										<div
											@click="allianceMenuOpen = !allianceMenuOpen"
											@click-outside="allianceMenuOpen = false"
											class="text-slate-600 dark:text-slate-300 cursor-pointer ml-2 text-sm tracking-tight dark:text-slate-300 p-2 rounded bg-slate-100 hover:bg-blue-100 dark:bg-slate-900 dark:hover:bg-slate-700 flex flex-1 flex align-middle md:mr-2 relative h-[40px] align-middle items-center"
										>
											<div class="overflow-hidden whitespace-nowrap text-ellipsis min-w-0 ... flex-1">
												{{ location.Name }}
											</div>
											<font-awesome-icon
												:icon="['far', 'chevron-down']"
												:class="{'rotate-[180deg]': allianceMenuOpen}"
												class="h-3 w-3 ml-2 my-auto transition-transform duration-200 ease-in-out"
											/>
										</div>
										<div class="relative ml-2 md:mr-2">
											<div
												:class="{'max-h-[2000px]  ': allianceMenuOpen}"
												class="max-h-0 transition-[max-height] duration-300 overflow-hidden absolute w-full max-w-100 rounded-b -mt-[3px] left-0 right-0 top-0"
											>
												<div class="text-sm bg-slate-100 dark:bg-slate-900 w-100 h-100">
													<div v-for="(company, index) in allianceCompanies" :key="index">
														<a
															class="text-tdc-blue-500 flex align-middle justify-between py-2 border-t border-slate-200 dark:border-slate-800 w-100 block hover:bg-blue-100 dark:hover:bg-slate-700 px-2"
															:href="company.Location_Portal_Link__c"
															target="_self"
														>
															<span>{{ company.Name }}</span>
															<font-awesome-icon
																:icon="['far', 'arrow-up-right-from-square']"
																class="h-2.5 w-2.5 ml-auto self-center"
															/>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-else class="ml-2 sm:ml-2 text-sm tracking-tight dark:text-slate-300">
										<span class="font-bold sm:mr-1">{{ location.Name }}</span>
										<span class="text-tdc-blue-500 hover:text-sky-600 hover:underline hidden sm:inline-block">
											<a
												v-if="location.URL_external_website_location__c"
												:href="location.URL_external_website_location__c"
												target="_blank"
											>
												[{{ $t("mol.header.link.view profile") }}]
											</a>
											<router-link v-else :to="{name: 'Profile'}">
												[{{ $t("mol.header.link.view profile") }}]
											</router-link>
										</span>
										<span v-if="mainContact" class="hidden sm:block"
											>{{ mainContact.FirstName }} {{ mainContact.LastName }}
										</span>
									</div>
								</div>
								<router-link :to="{name: 'Home'}" class="flex items-center ml-auto md:ml-2 md:order-first">
									<img
										src="~@/assets/images/logo-mydjcompany.png"
										alt="My DJ Company"
										class="dark:hidden mr-3 h-9 sm:h-12 object-scale-down"
									/>
									<img
										src="~@/assets/images/logo-mydjcompany-dark.png"
										alt="My DJ Company"
										class="hidden dark:block mr-3 h-9 sm:h-12 object-scale-down"
									/>
								</router-link>
								<div v-if="showOurBrands" class="hidden md:flex items-center ml-auto text-xs mr-2">
									<div class="border border-slate-300 dark:border-slate-600 rounded p-2.5 relative">
										<div
											class="absolute bg-white dark:bg-slate-800 dark:text-slate-300 -top-2.5 px-1 -left-0.5 text-slate-400"
										>
											{{ $t("mol.header.label.Our brands") }}
										</div>
										<div class="flex items-center justify-center">
											<img src="~@/assets/images/logo-thedjcompany.png" class="dark:hidden h-7 mr-1" />
											<img src="~@/assets/images/logo-thedjcompany-dark.png" class="hidden dark:block h-7 mr-1" />
											<img src="~@/assets/images/logo-locatiespotter-stacked.png" class="h-7 ml-1" />
										</div>
									</div>
								</div>
								<div class="hidden lg:flex items-center ml-auto">
									<div
										class="flex align-middle bg-slate-100 dark:bg-slate-600 dark:text-slate-100 rounded p-1 text-xs px-2 relative max-w-sm"
									>
										{{ $t("mol.general.string.Tette header string", {location: location.Name}) }}
										<div class="w-3 overflow-hidden inline-block absolute -right-3 top-1/3">
											<div class="h-6 bg-slate-100 dark:bg-slate-600 rotate-45 transform origin-top-left"></div>
										</div>
									</div>

									<img src="~@/assets/images/tette-cutout.png" class="h-20 mr-1 -bottom-1 relative -mt-1" />
								</div>
							</div>
						</div>
					</div>
					<router-view
						class="min-h-[calc(100vh-(100px*2)-20px)] sm:min-h-[calc(100vh-(110px*2))] md:min-h-[calc(100vh-(110px*2)-30px)] lg:min-h-[calc(100vh-(135px*2-30px)] mb-10"
					></router-view>
					<SlideOutPanel v-if="locationData" :panelOpen="onBoarding">
						<template slot="innerContent">
							<OnboardingForm />
						</template>
					</SlideOutPanel>
					<SlideOutPanel
						v-if="locationData"
						:panelOpen="showFeatureMessage"
						closeViaHaze
						@panelClosing="featureMessage = false"
					>
						<template slot="innerContent">
							<div class="absolute right-0">
								<button
									@click="featureMessage = false"
									class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
								>
									<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
								</button>
							</div>
							<FeatureMessage :closeFeatureMessage="!featureMessage" @closeMessageSlider="featureMessage = false" />
						</template>
					</SlideOutPanel>
				</div>
			</div>
		</div>
		<Footer :width="$route.meta.footerWidth" />
	</div>
</template>

<script>
import "@/assets/styles/mo-global.scss";
import {mapGetters} from "vuex";
import mainNavItems from "@/store/modules/location/mainNavItems";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import OnboardingForm from "@/components/location/OnboardingForm";
import FeatureMessage from "@/components/location/FeatureMessage";
import Footer from "@/components/location/Footer";
import Loader from "@/components/common/Loader";

export default {
	name: "locationLayout",
	components: {
		SlideOutPanel,
		OnboardingForm,
		FeatureMessage,
		Footer,
		Loader,
	},
	data() {
		return {
			mobileMainNavOpen: false,
			allianceMenuOpen: false,
			onBoarding: false,
			featureMessage: false,
			showFeatureMessage: false,
			mainNavItems: mainNavItems,
		};
	},
	computed: {
		...mapGetters({
			molSubnavHash: "getMolSubnavHash",
			initialPanelShown: "getInitialPanelShown",
			isLoading: "getLoadingState",
			error: "getError",
			errorMessage: "getErrorMessage",
			successMessage: "getSuccessMessage",
			location: "getLocationInformation",
			contacts: "getLocationContacts",
			mainContact: "getLocationMainContactDetails",
			allianceStatus: "getAllianceStatus",
			allianceInformation: "getAllianceInformation",
			allianceCompanies: "getAllianceCompanies",
			allianceCompanyCount: "getAllianceCompanyCount",
			countryCode: "getCountryCode",
			couponCount: "getCouponCount",
		}),
		showOurBrands() {
			if (this.countryCode === "NL") return true;
			return false;
		},
		locationData() {
			return this.location === null ? false : true;
		},
		firstTimeUser() {
			return (
				this.location.Location_Portal_First_Visit__c === null ||
				this.location.Location_Portal_First_Visit__c === undefined
			);
		},
		filteredNavItems() {
			return this.mainNavItems.filter((item) => {
				switch (item.displayWhen) {
					case "always":
						return true;
					case "hasCouponCode":
						return this.couponCount > 0;
					default:
						return false;
				}
			});
		},
	},
	mounted() {
		if (this.$route.hash) this.$store.commit("SET_MOL_SUBNAV_HASH", this.$route.hash);
		if (this.firstTimeUser && !this.initialPanelShown) {
			setTimeout(() => (this.onBoarding = this.firstTimeUser), 1000);
			this.$store.commit("SET_INITIAL_PANEL_SHOWN", true);
		} else if (this.location.LocationPortalFeatureMessage__c === "Available" && !this.initialPanelShown) {
			setTimeout(() => (this.showFeatureMessage = true), 1000);
			this.$store.commit("SET_INITIAL_PANEL_SHOWN", true);
			this.featureMessage = true;
		}
		if (this.allianceStatus) this.$store.dispatch("getAllianceInformation");
	},
	watch: {
		firstTimeUser: function () {
			if (this.locationData) {
				this.onBoarding = this.firstTimeUser;
			}
		},
		featureMessage: function () {
			if (this.locationData) {
				this.showFeatureMessage = this.featureMessage;
			}
		},
		allianceStatus: function () {
			if (this.allianceStatus) this.$store.dispatch("getAllianceInformation");
		},
	},
};
</script>
