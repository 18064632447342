export default [
	{
		label: "mol.mainNav.label.Home",
		icon: "home",
		route: {name: "Home"},
		hasSub: false,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Evaluation",
		icon: "hundred-points",
		route: {name: "ReviewUs"},
		hasSub: true,
		showNewLabel: true,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Venue profile",
		icon: "link",
		route: {name: "Profile", hash: "#upload-photos"},
		hasSub: true,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Book a DJ",
		icon: "calendar-plus",
		route: {name: "BookADJ"},
		hasSub: true,
		showNewLabel: true,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Venue contacts",
		icon: "users-cog",
		route: {name: "Contacts"},
		hasSub: true,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Reviews",
		icon: "comments",
		route: {name: "Reviews"},
		hasSub: false,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Venue spaces",
		icon: "champagne-glasses",
		route: {name: "Spaces"},
		hasSub: false,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.DJ bookings",
		icon: "calendar-day",
		route: {name: "DJBookings"},
		hasSub: false,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.How we work",
		icon: "chart-network",
		route: {name: "HowWeWork"},
		hasSub: false,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Contact us",
		icon: "comment-dots",
		route: {name: "ContactUs"},
		hasSub: false,
		showNewLabel: false,
		displayWhen: "always",
	},
	{
		label: "mol.mainNav.label.Coupon code",
		icon: "badge-check",
		route: {name: "CouponCode"},
		hasSub: false,
		showNewLabel: true,
		displayWhen: "hasCouponCode",
	},
];
