<template>
	<div>
		<PageTitle
			:title="$t('mol.onboardingForm.title.Welcome to your personal My DJ Company portal')"
			:subTitle="$t('mol.onboardingForm.title.We have a request')"
		/>
		<p class="mb-5">{{ $t("mol.onboardingForm.string.Onboarding intro") }}</p>
		<ContentTitle id="addressForm" adaptForm>{{ $t("mol.onboardingForm.title.Address") }}</ContentTitle>
		<InputItem
			v-model="onboardingLocation.BillingStreet"
			inputName="BillingStreet"
			:inputLabel="$t('mol.onboardingForm.label.Address')"
			:error="formErrors.addressErrorBillingStreet"
			:errorMessage="$t('mol.onboardingForm.message.Please enter an address')"
			@keypress="formErrors.addressErrorBillingStreet = false"
		/>
		<InputItem
			v-model="onboardingLocation.BillingPostalCode"
			inputName="BillingPostalCode"
			:inputLabel="$t('mol.onboardingForm.label.Postal code')"
			:error="formErrors.addressErrorBillingPostalCode"
			:errorMessage="$t('mol.onboardingForm.message.Please enter a postal code')"
			@keypress="formErrors.addressErrorBillingPostalCode = false"
		/>
		<InputItem
			v-model="onboardingLocation.BillingCity"
			inputName="BillingCity"
			:inputLabel="$t('mol.onboardingForm.label.City')"
			:error="formErrors.addressErrorBillingCity"
			:errorMessage="$t('mol.onboardingForm.message.Please enter a city')"
			@keypress="formErrors.addressErrorBillingCity = false"
			extraSpaceBottom
		/>
		<ContentTitle id="contactForm" adaptForm>{{ $t("mol.onboardingForm.title.Contact details") }}</ContentTitle>
		<ContactForm
			v-if="onboardingContact != null || onboardingContact != undefined"
			class="mb-5 block"
			onboarding
			:contact="onboardingContact"
			:formErrors="formErrors"
			ref="form"
		/>
		<ContentTitle id="spaceForm" adaptForm>{{ $t("mol.onboardingForm.title.Venue details") }}</ContentTitle>
		<SpaceForm
			v-if="onboardingSpace != null || onboardingSpace != undefined"
			onboarding
			:space="onboardingSpace"
			:formErrors="formErrors"
		/>
		<FormGridWrapper>
			<template slot="right">
				<Button
					@click.native="saveOnboarding(onboardingContact, onboardingSpace, onboardingLocation)"
					lg
					type="green"
					icon="check"
					class="mr-2"
					:label="$t('mol.onboardingForm.button.Confirm details')"
				/>
			</template>
		</FormGridWrapper>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import ContentTitle from "@/components/location/ContentTitle";
import InputItem from "@/components/location/InputItem";
import ContactForm from "@/components/location/ContactForm";
import SpaceForm from "@/components/location/SpaceForm";
import Button from "@/components/location/Button";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import {emailRegex} from "@/utilities";

export default {
	name: "OnboardingForm",
	components: {
		PageTitle,
		ContentTitle,
		InputItem,
		ContactForm,
		SpaceForm,
		Button,
		FormGridWrapper,
	},
	data() {
		return {
			formErrors: this.initialOnboardingFormErrors(),
		};
	},
	computed: {
		...mapGetters({
			molSubnavHash: "getMolSubnavHash",
			isLoading: "getLoadingState",
			error: "getError",
			errorMessage: "getErrorMessage",
			successMessage: "getSuccessMessage",
			location: "getLocationInformation",
			contacts: "getLocationContacts",
			contactCount: "getLocationContactCount",
			spaces: "getLocationSpaces",
			spaceCount: "getLocationSpaceCount",
			mainContact: "getLocationMainContactDetails",
		}),
		firstTimeUser() {
			return (
				this.location.Location_Portal_First_Visit__c === null ||
				this.location.Location_Portal_First_Visit__c === undefined
			);
		},
		onboardingContact() {
			if (this.contactCount) return this.contacts[0];
			return {
				Id: null,
				Salutation: "mevr.",
				FirstName: "",
				LastName: "",
				Title: "",
				Email: "",
				Phone: "",
				isMain: null,
			};
		},
		onboardingSpace() {
			if (this.spaceCount) return this.spaces[0];
			return {
				Id: null,
				Name: "",
				isMain: false,
				Details__c: "",
				space_description__c: "",
				Space_Name__c: this.$t("mol.spaceForm.label.Main space"),
				Space_profile_img_url__c: "",
				space_attr_guestsmax__c: "",
				space_attr_guestsmin__c: "",
				no_free_electric_network__c: false,
				no_free_parking__c: false,
				not_ground_floor__c: false,
				not_ground_floor_no_elevator__c: false,
				not_paved_road_to_unload__c: false,
				not_paved_road_unload_to_gig__c: false,
				small_doors__c: false,
				space_attr_aircon__c: false,
				space_attr_dancefloor__c: false,
				space_attr_decor_authentic__c: false,
				space_attr_decor_browncafe__c: false,
				space_attr_decor_classic__c: false,
				space_attr_decor_elegant__c: false,
				space_attr_decor_historic__c: false,
				space_attr_decor_homey__c: false,
				space_attr_decor_industrial__c: false,
				space_attr_decor_modern__c: false,
				space_attr_decor_professional__c: false,
				space_attr_decor_romantic__c: false,
				space_attr_decor_rural__c: false,
				space_attr_decor_rustic__c: false,
				space_attr_decor_trendy__c: false,
				space_attr_disabwc__c: false,
				space_attr_livemusic__c: false,
				space_attr_maxdb__c: "",
				space_attr_outside__c: false,
				space_attr_presentation__c: false,
				space_attr_size__c: "",
				space_attr_smoking__c: false,
				space_attr_soundlimit__c: false,
				space_attr_wheelchair__c: false,
				space_attr_wifi__c: false,
				unload_25_meter__c: false,
			};
		},
		onboardingLocation() {
			var location = {};
			location["Location_Portal_First_Visit__c"] = this.currentDateTime;
			location["BillingStreet"] = this.location.BillingStreet;
			location["BillingPostalCode"] = this.location.BillingPostalCode;
			location["BillingCity"] = this.location.BillingCity;
			return location;
		},
		currentDateTime() {
			return new Date().toISOString();
		},
	},
	methods: {
		initialOnboardingFormErrors() {
			return {
				hasErrors: false,
				addressErrorBillingStreet: false,
				addressErrorBillingPostalCode: false,
				addressErrorBillingCity: false,
				contactErrorFirstName: false,
				contactErrorLastName: false,
				contactErrorEmail: false,
				contactErrorPhone: false,
				spaceErrorspace_attr_size__c: false,
				spaceErrorspace_attr_maxdb__c: false,
			};
		},
		validateValue(value) {
			return value === undefined || value === null || value == "";
		},
		validateOnboarding(contact, space, location) {
			this.resetFormErrors();
			if (this.validateValue(location.BillingStreet)) {
				this.formErrors.addressErrorBillingStreet = true;
			}
			if (this.validateValue(location.BillingPostalCode)) {
				this.formErrors.addressErrorBillingPostalCode = true;
			}
			if (this.validateValue(location.BillingCity)) {
				this.formErrors.addressErrorBillingCity = true;
			}
			if (this.validateValue(contact.FirstName)) {
				this.formErrors.contactErrorFirstName = true;
			}
			if (this.validateValue(contact.LastName)) {
				this.formErrors.contactErrorLastName = true;
			}
			if (!emailRegex.test(contact.Email)) {
				this.formErrors.contactErrorEmail = true;
			}
			if (!this.$refs.form.$refs.phone.isValidPhoneNumber()) {
				this.formErrors.contactErrorPhone = true;
				this.formErrors.hasErrors = true;
			}
			if (!this.validateValue(space.space_attr_size__c) && !/^\d+$/.test(space.space_attr_size__c)) {
				this.formErrors.spaceErrorspace_attr_size__c = true;
			}
			if (!this.validateValue(space.space_attr_maxdb__c) && !/^\d+$/.test(space.space_attr_maxdb__c)) {
				this.formErrors.spaceErrorspace_attr_maxdb__c = true;
			}

			if (
				this.validateValue(location.BillingStreet) ||
				this.validateValue(location.BillingPostalCode) ||
				this.validateValue(location.BillingCity)
			) {
				this.formErrors.hasErrors = true;
				document.getElementById("addressForm").scrollIntoView({behavior: "smooth"});
			} else if (
				this.validateValue(contact.FirstName) ||
				this.validateValue(contact.LastName) ||
				!this.$refs.form.$refs.phone.isValidPhoneNumber()
			) {
				this.formErrors.hasErrors = true;
				document.getElementById("contactForm").scrollIntoView({behavior: "smooth"});
			} else if (!emailRegex.test(contact.Email)) {
				this.formErrors.hasErrors = true;
				document.getElementById("contactForm").scrollIntoView({behavior: "smooth"});
			} else if (
				(!this.validateValue(space.space_attr_size__c) && !/^\d+$/.test(space.space_attr_size__c)) ||
				(!this.validateValue(space.space_attr_maxdb__c) && !/^\d+$/.test(space.space_attr_maxdb__c))
			) {
				this.formErrors.hasErrors = true;
				document.getElementById("spaceForm").scrollIntoView({behavior: "smooth"});
			}
		},
		saveOnboarding(contact, space, location) {
			this.validateOnboarding(contact, space, location);
			if (!this.formErrors.hasErrors) {
				this.$store
					.dispatch("upsertSFData", {
						action: "upsertContact",
						data: contact,
						successMessage: this.$t("mol.contactForm.message.Contact saved"),
						errorMessage: this.$t("mol.contactForm.message.Contact not saved"),
						handleSuccessManually: true,
					})
					.then((response) => {
						if (response) {
							return this.$store.dispatch("upsertSFData", {
								action: "upsertLocationSpace",
								data: space,
								successMessage: this.$t("mol.spaceForm.message.Venue space saved"),
								errorMessage: this.$t("mol.spaceForm.message.Venue space not saved"),
								handleSuccessManually: true,
							});
						}
					})
					.then((response) => {
						if (response) {
							return this.$store.dispatch("upsertSFData", {
								action: "updateLocationInformation",
								data: location,
								successMessage: this.$t("mol.onboardingForm.message.Information saved"),
								errorMessage: this.$t("mol.onboardingForm.message.Information not saved"),
							});
						}
					})
					.then((response) => {
						if (response) {
							this.$store.dispatch("getLocationInformation");
						}
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},
		resetFormErrors() {
			this.formErrors = this.initialOnboardingFormErrors();
		},
	},
	mounted() {
		if (this.firstTimeUser) this.location.Location_Portal_First_Visit__c = this.currentDateTime;
	},
};
</script>
