<template>
	<div>
		<PageTitle
			:title="$t('mol.featureMsg.title.My DJ Company is improved')"
			:subTitle="$t('mol.featureMsg.title.Easily book a DJ online and write a review about us')"
		/>

		<div class="dark:text-white text-lg font-bold mb-2">1. {{ $t("mol.featureMsg.title.Review The DJ Company") }}</div>
		<p class="text-base dark:text-white mb-4">{{ $t("mol.featureMsg.string.Satisfied? Let us know") }}</p>
		<p class="text-base dark:text-white mb-4">
			{{ $t("mol.featureMsg.string.Create a review in 2 minutes") }}
			<Ahref @click.native="saveMsgSeen('ReviewUs')">{{ $t("mol.featureMsg.string.See the review here") }}</Ahref
			>.
		</p>
		<Button
			type="green"
			@click.native="saveMsgSeen('ReviewUs')"
			:label="$t('mol.featureMsg.button.Fill in evaluation')"
		/>
		<div class="dark:text-white text-lg font-bold mb-2 mt-7 lg:mt-12">
			2. {{ $t("mol.featureMsg.title.See prices and book a DJ online") }}
		</div>
		<ul class="mb-4">
			<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
				<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
				{{ $t("common.usp.string.No cure, no pay") }}
			</li>
			<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
				<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
				{{ $t("common.usp.string.Free cancellation 14 days before party date") }}
			</li>
			<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
				<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
				{{ $t("common.usp.string.100% match with your DJ") }}
			</li>
			<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
				<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
				{{ $t("mol.featureMsg.string.Settle invoice in advance or afterwards") }}
			</li>
		</ul>
		<Button @click.native="saveMsgSeen('BookADJ')" :label="$t('mol.general.button.See pricing')" />
		<div class="mt-4 border-t border-slate-50 dark:border-slate-600 pt-5">
			<Button @click.native="saveMsgSeen()" :label="$t('mol.general.button.Close')" type="slate" icon="times" />
		</div>
	</div>
</template>

<script>
import PageTitle from "@/components/location/PageTitle";
import Button from "@/components/location/Button";
import Ahref from "@/components/location/Ahref";

export default {
	name: "FeatureMessage",
	props: {
		closeFeatureMessage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			saving: false,
		};
	},
	components: {
		PageTitle,
		Button,
		Ahref,
	},
	computed: {
		location() {
			var location = {};
			location["LocationPortalFeatureMessage__c"] = this.currentDateTime;
			return location;
		},
		currentDateTime() {
			return new Date().toISOString();
		},
	},
	methods: {
		saveMsgSeen(destinationName = "") {
			if (!this.saving) {
				this.saving = true;
				this.$emit("closeMessageSlider");
				this.$store
					.dispatch("upsertSFData", {
						action: "updateLocationInformation",
						data: this.location,
						successMessage: this.$t("mol.onboardingForm.message.Information saved"),
						errorMessage: this.$t("mol.onboardingForm.message.Information not saved"),
						handleSuccessManually: true,
					})
					.then((response) => {
						if (response) {
							this.$store.dispatch("getLocationInformation");

							if (destinationName != "") {
								this.$router.push({name: destinationName});
							}
						}
					})
					.catch((err) => {
						console.error(err);
						this.saving = false;
					});
			}
		},
	},
	watch: {
		closeFeatureMessage: function () {
			if (this.closeFeatureMessage) {
				this.saveMsgSeen();
			}
		},
	},
};
</script>
