<template>
	<div class="flex flex-nowrap flex-shrink-0 h-full overflow-x-clip overscroll-y-auto">
		<div
			:class="[
				{hidden: hideFooter},
				{'max-w-[1400px] md:w-[calc(100%-250px)] md:ml-[250px] xl:w-[calc(100%-300px)] xl:ml-[300px]': fullWidth},
				{
					'max-w-[1000px] md:w-[calc(100%-250px)] lg:w-[calc(100%-(300px+270px))] xl:w-[calc(100%-(300px+400px))] lg:w-[calc(100%-(250px+300px))] md:ml-[250px] lg:ml-[calc(250px+320px)]  xl:ml-[calc(300px+400px)]':
						adaptForSubnav,
				},
			]"
			class="w-full ml-0"
		>
			<!-- :class="[{'sm:grid sm:grid-cols-2 xl:grid-cols-3': fullWidth}, {'2xl:grid 2xl:grid-cols-2': adaptForSubnav}]" -->
			<div
				:class="[
					{'lg:grid lg:grid-cols-[minmax(2fr, 450px)_245px]': fullWidth},
					{'2xl:grid 2xl:grid-cols-[calc(100% - 225px)_245px]': adaptForSubnav},
				]"
				class="sm:mx-4 md:mx-5 xl:mx-10 pt-5 pb-28 relative"
			>
				<div
					class="from-slate-200 to-slate-100 dark:from-slate-700 dark:to-slate-900 bg-gradient-to-b absolute w-full top-0 h-[50%] right-0 -z-10 border-t-4 border-slate-300 dark:border-slate-500"
				></div>
				<div class="px-0 sm:px-5 xl:px-7">
					<ul
						class="md:grid md:grid-cols-2 [&>*]:py-2 [&>*]:px-3 sm:[&>*]:px-0 lg:[&>*]:pr-3 lg:[&>*]:py-3 divide-y divide-dashed divide-slate-300 dark:divide-slate-600 [&>*]:flex [&>*]:justify-start [&>*]:items-start text-xs sm:text-sm"
					>
						<li class="font-bold col-span-2">
							{{ $t("mol.footer.title.Navigate to") }}
						</li>
						<li v-for="(item, index) in filteredNavItems" :key="index">
							<router-link
								:to="{name: item.route.name}"
								exact-active-class=""
								active-class=""
								class="block w-full hover:underline"
							>
								{{ $t(item.label) }}
							</router-link>
						</li>
					</ul>
				</div>

				<div class="px-0 sm:px-5 xl:px-7 mb-4">
					<ul
						class="[&>*]:py-2 [&>*]:px-3 sm:[&>*]:px-0 lg:[&>*]:py-3 divide-y divide-transparent [&>*>a]:flex [&>*>a]:justify-start [&>*>a]:items-start text-xs sm:text-sm"
					>
						<li class="font-bold border-b border-dashed border-slate-300 dark:border-slate-600">
							{{ $t("mol.footer.title.Need help or advise?") }}
						</li>
						<li>
							<a :href="'tel:' + tdcPhoneNumber" target="_blank">
								<div class="w-10 h-10 flex justify-center items-center bg-blue-500 rounded-lg text-white mr-2">
									<font-awesome-icon :icon="['far', 'phone']" class="w-4 h-4" />
								</div>
								<div>
									{{ tdcPhoneNumber }}
									<div class="text-xs text-slate-500 flex items-center">
										<font-awesome-icon :icon="['fas', 'circle']" class="w-2 h-2 text-tdc-green-500 mr-1" />{{
											$t("mol.footer.label.Available now")
										}}
									</div>
								</div>
							</a>
						</li>
						<li>
							<a :href="'https://wa.me/' + $t('mol.general.contact.whatsapp number')" target="_blank">
								<div
									class="w-10 h-10 flex justify-center items-center bg-gradient-to-tr from-[#128c7e] to-[#25d366] rounded-lg text-white mr-2"
								>
									<font-awesome-icon :icon="['fab', 'whatsapp']" class="w-5 h-5" />
								</div>
								<div>
									{{ $t("common.general.contact.whatsapp number shown") }}
									<div class="text-xs text-slate-500 flex items-center">
										<font-awesome-icon :icon="['far', 'stopwatch']" class="w-3 h-3 mr-1" />{{
											$t("mol.footer.label.Immediate response")
										}}
									</div>
								</div>
							</a>
						</li>
						<li>
							<a :href="'mailto:' + $t('mol.general.contact.info mail address')" target="_blank">
								<div class="w-10 h-10 flex justify-center items-center bg-red-600 rounded-lg text-white mr-2">
									<font-awesome-icon :icon="['far', 'envelope']" class="w-4 h-4" />
								</div>
								<div>
									{{ $t("mol.general.contact.info mail address") }}
									<div class="text-xs text-slate-500 flex items-center">
										<font-awesome-icon :icon="['far', 'stopwatch']" class="w-3 h-3 mr-1" />{{
											$t("mol.footer.label.Same day response")
										}}
									</div>
								</div>
							</a>
						</li>
						<li>
							<a href="https://calendly.com/thedjcompany/gesprek-met-the-dj-company" target="_blank">
								<div class="w-10 h-10 flex justify-center items-center bg-tdc-blue-500 rounded-lg text-white mr-2">
									<font-awesome-icon :icon="['far', 'calendar-day']" class="w-4 h-4" />
								</div>
								<div>
									{{ $t("mol.footer.label.Make a callback appointment") }}
									<div class="text-xs text-slate-500 flex items-center">
										<font-awesome-icon :icon="['far', 'computer-mouse']" class="w-3 h-3 mr-1" />{{
											$t("mol.footer.label.Plan online")
										}}
									</div>
								</div>
							</a>
						</li>
					</ul>
				</div>

				<div class="px-0 sm:px-5 xl:px-7 mb-4">
					<ul
						class="[&>*]:py-2 [&>*]:px-3 sm:[&>*]:px-0 lg:[&>*]:py-3 divide-y divide-dashed divide-slate-300 dark:divide-slate-600 [&>*]:flex [&>*]:justify-start [&>*]:items-start text-xs sm:text-sm"
					>
						<li class="font-bold col-span-2">
							{{ $t("mol.footer.title.Book a DJ care free") }}
						</li>
						<li>
							<font-awesome-icon :icon="['fas', 'check']" class="w-4 h-4 mr-2 text-tdc-green-500" />
							{{ $t("mol.footer.string.No cure, no pay") }}
						</li>
						<li>
							<font-awesome-icon :icon="['fas', 'check']" class="w-4 h-4 mr-2 text-tdc-green-500" />
							{{ $t("mol.footer.string.Free cancellation 14 days before party date") }}
						</li>

						<li>
							<font-awesome-icon :icon="['fas', 'check']" class="w-4 h-4 mr-2 text-tdc-green-500" />
							{{ $t("mol.footer.string.100% match with your DJ") }}
						</li>
						<li>
							<font-awesome-icon :icon="['fas', 'check']" class="w-4 h-4 mr-2 text-tdc-green-500" />
							{{ $t("mol.footer.string.10 years The DJ Company, 150 euro discount") }}
						</li>
					</ul>
				</div>
				<div v-if="moTld == 'nl'" class="px-0 sm:px-5 xl:px-7 mb-4">
					<ul
						class="flex flex-row flex-wrap [&>*]:py-2 [&>*]:px-1 sm:[&>*]:px-0 lg:[&>*]:py-3 divide-y divide-transparent [&>*>a]:flex [&>*>a]:justify-start [&>*>a]:items-start text-xs sm:text-sm"
					>
						<li class="font-bold border-b border-dashed border-slate-300 dark:border-slate-600 col-span-3 w-full !px-3">
							{{ $t("mol.footer.title.Follow us") }}
						</li>
						<li class="!pl-3">
							<a href="https://www.linkedin.com/company/the-dj-company-nl-" target="_blank">
								<div
									class="mr-1 md:mr-3 w-10 h-10 flex justify-center items-center bg-[#0a66c2] rounded-full text-white"
								>
									<font-awesome-icon :icon="['fab', 'linkedin-in']" class="w-4 h-4" />
								</div>
							</a>
						</li>
						<li>
							<a href="https://www.facebook.com/thedjcompanynl/" target="_blank">
								<div
									class="mr-1 md:mr-3 w-10 h-10 flex justify-center items-center bg-[#1877f2] rounded-full text-white"
								>
									<font-awesome-icon :icon="['fab', 'facebook-f']" class="w-4 h-4" />
								</div>
							</a>
						</li>
						<li>
							<a href="https://www.instagram.com/thedjcompanynl/" target="_blank">
								<div
									class="mr-1 md:mr-3 w-10 h-10 flex justify-center items-center rounded-full text-white bg-gradient-to-tr from-[#fdf497] from-12% via-[#fd5949] via-40% via-[#d6249f] via-50% to-[#285AEB] to-90%"
								>
									<font-awesome-icon :icon="['fab', 'instagram']" class="w-4 h-4" />
								</div>
							</a>
						</li>
						<li>
							<a href="https://www.youtube.com/channel/UCtVl0JX72_SrWBt-A-y17kg" target="_blank">
								<div class="w-10 h-10 flex justify-center items-center bg-[#ff0000] rounded-full text-white">
									<font-awesome-icon :icon="['fab', 'youtube']" class="w-4 h-4" />
								</div>
							</a>
						</li>
					</ul>
					<ul
						class="flex flex-row flex-wrap [&>*]:py-2 [&>*]:px-1 sm:[&>*]:px-0 lg:[&>*]:py-3 divide-y divide-transparent [&>*>a]:flex [&>*>a]:justify-start [&>*>a]:items-start text-xs sm:text-sm"
					>
						<li class="font-bold border-b border-dashed border-slate-300 dark:border-slate-600 col-span-3 w-full !px-3">
							{{ $t("mol.footer.title.Powered by") }}
						</li>
						<li class="!pl-3">
							<img
								src="~@/assets/images/brand-scanfie-light.png"
								alt="Scanfie"
								class="dark:hidden mr-3 h-7 sm:h-8 object-scale-down"
							/>
							<img
								src="~@/assets/images/brand-scanfie-dark.png"
								alt="Scanfie"
								class="hidden dark:block mr-3 h-7 sm:h-8 object-scale-down"
							/>
							<div class="flex items-center mt-2">
								<a
									class="text-xs"
									href="https://www.scanfie.nl/?utm_source=thedjcompany&utm_medium=website&utm_campaign=alles-in-1"
									target="_blank"
									><Ahref
										>{{ $t("mol.footer.label.Try free") }}
										<font-awesome-icon :icon="['far', 'chevron-right']" class="h-3 w-3" /></Ahref
								></a>
							</div>
						</li>
					</ul>
				</div>
				<div
					:class="[{'col-span-2': fullWidth}, {'col-span-2': adaptForSubnav}]"
					class="px-3 sm:px-5 xl:px-7 mb-4 md:order-last"
				>
					<div class="flex flex-row w-full gap-3">
						<div class="flex justify-center flex-col flex-grow bg-white dark:bg-slate-700 rounded p-2">
							<div class="text-xs text-slate-400 dark:text-slate-300 text-center">
								{{ $t("mol.footer.label.Pay securely") }}
							</div>
							<div class="flex flex-row justify-center w-full">
								<img
									v-if="moTld == 'be'"
									class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded"
									src="~@/assets/images/betalen-bancontact.png"
								/>
								<img
									v-if="moTld == 'nl'"
									class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded"
									src="~@/assets/images/betalen-ideal.png"
								/>
								<img class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded" src="~@/assets/images/betalen-mc.png" />
								<img class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded" src="~@/assets/images/betalen-maestro.png" />
								<img class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded" src="~@/assets/images/betalen-visa.png" />
								<img class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded" src="~@/assets/images/betalen-paypal.png" />
							</div>
						</div>
						<div class="flex justify-center flex-col flex-grow bg-white dark:bg-slate-700 rounded p-2">
							<div class="text-xs text-slate-400 dark:text-slate-300 text-center">
								<span v-if="moTld == 'nl'">{{ $t("mol.footer.label.Seen from") }}</span>
								<span v-if="moTld == 'be'">{{ $t("mol.footer.label.Book safely") }}</span>
							</div>
							<div class="flex flex-row justify-center w-full">
								<img
									v-if="moTld == 'nl'"
									class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded"
									src="~@/assets/images/seenfrom-rtl4.png"
								/>
								<img
									v-if="moTld == 'nl'"
									class="w-7 mx-1 mt-2 dark:bg-white dark:p-1 rounded"
									src="~@/assets/images/seenfrom-tpw.png"
								/>
								<img
									v-if="moTld == 'be'"
									class="h-5 mx-1 mt-2 dark:bg-white dark:p-1 rounded"
									src="~@/assets/images/brand-safeshops-trimmed.png"
								/>
								<img
									v-if="moTld == 'be'"
									class="w-7 mx-1 mt-2"
									src="~@/assets/images/brand-trustecommerceeu-trimmed.png"
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					:class="[{'col-span-2': fullWidth}, {'col-span-2': adaptForSubnav}]"
					class="mt-10 flex items-center justify-center px-1 text-xs md:order-last"
				>
					<div>
						<img src="~@/assets/images/logo-thedjcompany.png" alt="The DJ Company" class="dark:hidden h-9" />
						<img src="~@/assets/images/logo-thedjcompany-dark.png" alt="The DJ Company" class="hidden dark:block h-9" />
					</div>
					<div class="border-l-slate-300 dark:border-l-slate-600 border-t-0 border-r-0 border-b-0 border pl-2 ml-2">
						<div class="dark:text-slate-400"><span class="text-base font-bold">9.2</span>/10</div>
						<div class="text-amber-500">
							<font-awesome-icon :icon="['fas', 'star']" class="h-3" />
							<font-awesome-icon :icon="['fas', 'star']" class="h-3" />
							<font-awesome-icon :icon="['fas', 'star']" class="h-3" />
							<font-awesome-icon :icon="['fas', 'star']" class="h-3" />
							<font-awesome-icon :icon="['fas', 'star-half-alt']" class="h-3" />
						</div>
						<div class="text-tdc-blue-500">{{ $t("mol.footer.label.reviews count", {count: "12,000+"}) }}</div>
					</div>
				</div>

				<!-- <div class="flex items-center justify-center px-1 py-2 text-xs dark:text-slate-500">
				&copy; 2011 - {{ new Date().getFullYear() }} The DJ Company {{ width }}
				
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import mainNavItems from "@/store/modules/location/mainNavItems";
import Ahref from "@/components/location/Ahref";

export default {
	name: "Footer",
	components: {
		Ahref,
	},
	props: {
		width: {
			type: String,
			default: "none",
		},
	},
	data() {
		return {
			mobileSubNavOpen: true,
			activeItem: null,
			navOpenScrolledAmount: 0,
			mdScreenWidth: 1024,
			mainNavItems: mainNavItems,
		};
	},
	computed: {
		...mapGetters({
			location: "getLocationInformation",
			moTld: "getTld",
			tdcPhoneNumber: "getTdcPhoneNumber",
			couponCount: "getCouponCount",
		}),
		hideFooter() {
			return this.width == "none";
		},
		fullWidth() {
			return this.width == "full";
		},
		adaptForSubnav() {
			return this.width == "half";
		},
		displaySocials() {
			return this.moTld === "nl";
		},
		filteredNavItems() {
			return this.mainNavItems.filter((item) => {
				switch (item.displayWhen) {
					case "always":
						return true;
					case "hasCouponCode":
						return this.couponCount > 0;
					default:
						return false;
				}
			});
		},
	},
};
</script>
